import Main from './Pages/Main/Main'

function App() {
  return (
    <div className="">
      <Main/>
    </div>
  )
}

export default App
